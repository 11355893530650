import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import { graphql, Link, useStaticQuery } from 'gatsby';
import 'twin.macro';
import { OtherServicesQuery } from '../../../../generated/graphql-types';

const OtherPricing = ({
  accompagnamento,
  commissioni,
  compagnia,
}: {
  accompagnamento?: boolean;
  commissioni?: boolean;
  compagnia?: boolean;
}) => {
  const services = useStaticQuery<OtherServicesQuery>(graphql`
    query OtherServices {
      accompagnamento: file(
        relativePath: { eq: "accompagnamento_service.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      commissioni: file(relativePath: { eq: "commissioni_service.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      compagnia: file(relativePath: { eq: "compagnia_service.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <div tw="flex flex-wrap -mx-4">
      {accompagnamento ? (
        <div tw="w-full md:w-1/3 flex px-4 mb-10">
          <div tw="flex flex-col flex-1">
            <div tw="mb-auto">
              <BackgroundImage
                fluid={services.accompagnamento.childImageSharp.fluid}
                style={{ height: '220px', width: '100%' }}
                tw="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Accompagnamento</Heading>
            </div>
            <div>
              <Link to="/servizio-accompagnamento-anziani/">
                <Button variant="filled" color="primary">
                  Vai al servizio
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {commissioni ? (
        <div tw="w-full md:w-1/3 flex px-4 mb-10">
          <div tw="flex flex-col flex-1">
            <div tw="mb-auto">
              <BackgroundImage
                fluid={services.commissioni.childImageSharp.fluid}
                style={{ height: '220px', width: '100%' }}
                tw="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Commissioni</Heading>
            </div>
            <div>
              <Link to="/spesa-e-commissioni-domicilio-anziani/">
                <Button variant="filled" color="primary">
                  Vai al servizio
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {compagnia ? (
        <div tw="w-full md:w-1/3 flex px-4 mb-10">
          <div tw="flex flex-col flex-1">
            <div tw="mb-auto">
              <BackgroundImage
                fluid={services.compagnia.childImageSharp.fluid}
                style={{ height: '220px', width: '100%' }}
                tw="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Compagnia</Heading>
            </div>
            <div>
              <Link to="/compagnia-anziani/">
                <Button variant="filled" color="primary">
                  Vai al servizio
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OtherPricing;
