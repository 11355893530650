import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { PartnerBannerQuery } from '../../../../generated/graphql-types';

const PartnerBanner = () => {
  const partnerBannerData = useStaticQuery<PartnerBannerQuery>(graphql`
    query PartnerBanner {
      panorama: file(relativePath: { eq: "pricing/panorama.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage fluid={partnerBannerData.panorama.childImageSharp.fluid}>
      <div tw="bg-black bg-opacity-60 py-16 px-10 md:p-20">
        <Heading variant="h5" tw="text-white text-center">
          Partner e Convenzioni
        </Heading>
        <p tw="text-white text-paragraph text-center mb-5">
          Lavoriamo ogni giorno per stipulare convenzioni e partnership con
          strutture socio sanitarie e assistenziali ed altri erogatori di
          servizi alla persona.
        </p>
        <div tw="flex justify-center">
          <a href="/i-partner-di-ugo/">
            <Button variant="filled" color="primary">
              Scopri le convenzioni attive
            </Button>
          </a>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default PartnerBanner;
