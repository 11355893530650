import Heading from '../../core/typography/Heading';
import Image from 'gatsby-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import 'twin.macro';
import { SecurePaymentQuery } from '../../../../generated/graphql-types';

const SecurePayment = () => {
  const prezziCompagnia = useStaticQuery<SecurePaymentQuery>(graphql`
    query SecurePayment {
      iconSafe: file(relativePath: { eq: "icon_safe.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      iconSafe2: file(relativePath: { eq: "icon_safe_2.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <Heading variant="h3" tw="text-center mb-16">
        Pagamento online diretto e sicuro
      </Heading>
      <div tw="flex flex-wrap -mx-4 md:-mx-10">
        <div tw="w-full md:w-1/2 px-4 md:px-10 mb-10 md:mb-0 flex flex-col">
          <div tw="rounded-card p-8 shadow-card flex-1">
            <div tw="flex justify-center mb-4">
              <Image fixed={prezziCompagnia.iconSafe.childImageSharp.fixed} />
            </div>
            <Heading variant="h5" tw="text-center">
              Addebito post servizio
            </Heading>
            <p tw="text-paragraph">
              In fase di prenotazione ti chiediamo di inserire nel profilo
              personale un metodo di pagamento valido. Per noi è una garanzia
              importante ma non ti viene addebitato nulla. Il pagamento avviene
              a fine servizio: il sistema calcola la durata effettiva e ti viene
              addebitato il costo esatto. In questo modo hai un servizio
              flessibile e paghi solo quello che usi!
            </p>
          </div>
        </div>
        <div tw="w-full md:w-1/2 px-4 md:px-10 flex flex-col">
          <div tw="rounded-card p-8 shadow-card flex-1">
            <div tw="flex justify-center mb-4">
              <Image fixed={prezziCompagnia.iconSafe2.childImageSharp.fixed} />
            </div>
            <Heading variant="h5" tw="text-center">
              Pagamenti certificati e sicuri
            </Heading>
            <p tw="text-paragraph">
              Il nostro sistema di pagamento è sicuro e tracciato. Si chiama
              Stripe e viene usato dai migliori servizi online. I tuoi dati sono
              gestiti in massima sicurezza e inaccessibili. Puoi inserire come
              metodo di pagamento qualsiasi carta abilitata per pagare online:
              carta di credito, Bancomat, prepagata. Per qualsiasi problema il
              nostro Servizio Clienti è pronto ad aiutarti.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurePayment;
