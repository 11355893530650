import React from 'react';
import Container from '../../core/layout/Layout/Container';
import 'twin.macro';
import Heading from '../../core/typography/Heading';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { OrangeBanner2Query } from '../../../../generated/graphql-types';
import Image from 'gatsby-image';
import Button from '../../core/buttons/Button';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';
import useDatalayer from '../../../../hooks/use-datalayer';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';

const PromoBanner = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );
  const analytics = useAnalytics();

  const datalayer = useDatalayer();

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const data = useStaticQuery<OrangeBanner2Query>(graphql`
    query OrangeBanner2 {
      image: file(relativePath: { eq: "invoice_grandma.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            presentationHeight
            presentationWidth
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <div>
      <div
        style={{
          backgroundColor: '#f8b142',
        }}
      >
        <Container>
          <div tw="relative py-10 pt-0 md:pt-10">
            <div tw="w-full lg:w-3/4 z-10 py-20 lg:py-6 z-10 relative">
              <Heading
                variant="h3"
                tw="text-white text-center md:text-left italic text-4xl mb-0 mb-8"
              >
                Usa il <b>preventivatore automatico</b> per il tuo
                <br />
                servizio su misura. <b>Senza impegno, in pochi clic!</b>
              </Heading>
              <div tw="flex justify-center md:block">
                <Link to="/#hero">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                        location: 'm-qf-promo_banner_button',
                      });
                    }}
                  >
                    Calcola un preventivo
                  </Button>
                </Link>
              </div>
            </div>
            <div tw="pt-10 lg:pt-0 w-1/2 lg:w-1/4 md:transform flex overflow-visible absolute right-0 bottom-0">
              <Image tw="w-full" fluid={data.image.childImageSharp.fluid} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PromoBanner;
