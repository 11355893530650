import { graphql, useStaticQuery } from 'gatsby';
import { ThreeStepsQuery } from '../../../../generated/graphql-types';
import tw from 'twin.macro';
import Image from 'gatsby-image';
import Heading from '../../core/typography/Heading';
import ResponsiveList from '../../views/ResponsiveList/ResponsiveList';
import React from 'react';

const ThreeSteps = () => {
  const threeStepsData = useStaticQuery<ThreeStepsQuery>(graphql`
    query ThreeSteps {
      numberOne: file(relativePath: { eq: "numbers/1.png" }) {
        childImageSharp {
          fixed(height: 130) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      numberTwo: file(relativePath: { eq: "numbers/2.png" }) {
        childImageSharp {
          fixed(height: 130) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      numberThree: file(relativePath: { eq: "numbers/3.png" }) {
        childImageSharp {
          fixed(height: 130) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
    }
  `);
  return (
    <ResponsiveList
      sliderProps={{
        darkControls: true,
        navOutside: true,
      }}
      twGridItemSetting={tw`md:w-1/3 px-10 py-4`}
    >
      <div>
        <div tw="flex justify-center mb-4">
          <Image fixed={threeStepsData.numberOne.childImageSharp.fixed} />
        </div>
        <Heading variant="h5" tw="text-center">
          Preventivo su misura
        </Heading>
        <p tw="text-paragraph text-center mb-4">
          UGO ti accompagna e aiuta negli impegni di salute e quotidiani.
        </p>
      </div>
      <div>
        <div tw="flex justify-center mb-4">
          <Image fixed={threeStepsData.numberTwo.childImageSharp.fixed} />
        </div>
        <Heading variant="h5" tw="text-center">
          Paghi ciò che usi
        </Heading>
        <p tw="text-paragraph text-center mb-4">
          Scegli tu come spostarti: a piedi, coi mezzi, con la macchina di UGO.
        </p>
      </div>
      <div>
        <div tw="flex justify-center mb-4">
          <Image fixed={threeStepsData.numberThree.childImageSharp.fixed} />
        </div>
        <Heading variant="h5" tw="text-center">
          Addebito post servizio
        </Heading>
        <p tw="text-paragraph text-center mb-4">
          Operatori qualificati, servizio recensito, pagamento tracciato.
        </p>
      </div>
    </ResponsiveList>
  );
};

export default ThreeSteps;
